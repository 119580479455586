import React, { PropsWithoutRef, useEffect, useState } from 'react';
import XLSX from 'xlsx';
import { getBrowseCaseStudy } from '../../services/ProductService';
import { useSearchParams } from 'react-router-dom';
import Tabs from '../../components/common/Tabs/Tabs';
import styles from './ProductPage.module.scss';
import ProductCaseStudies from '../../components/ProductPage/ProductCaseStudies/ProductCaseStudies';
import { useSelector } from 'react-redux';
import { RootStore } from '../../store';
import NavigationHeader from '../../components/common/NavigationHeader/NavigationHeader';
import { PreviousLink } from '../../models/previous-link';
import ContainerForDrawer from '../../components/common/ContainerForDrawer/ContainerForDrawer';
import LeftDrawer from '../../components/common/LeftDrawer/LeftDrawer';
import RightContainer from '../../components/common/RightContainer/RightContainer';
import IconField from '../../components/common/IconField/IconField';
import { ReactComponent as DocumentGreen } from '../../icons/document-green.svg';
import { ReactComponent as XlsxFileIcon } from '../../../src/icons/xlsx-file.svg';
import { useIntl, FormattedMessage } from 'react-intl';
import { getAlphaDigitName } from '../../../src/utils/string.util';
import _, { find } from 'lodash';
import { RangeFamily } from '../../models/admin-form-types';
import { getRangeFamilies } from '../../services/FamilyService';
import { Sheet } from 'xlsx';

type caseStudiesProps = {
  exportFileName?: string;
};

const CaseStudies = ({ exportFileName = 'CaseStudy' }: caseStudiesProps) => {
  const [searchParams, setSearchParams] = useSearchParams({
    subSectorId: '',
    applicationId: '',
  });
  const [tab, setTab] = useState('CASE STUDIES');
  const [caseStudies, setcaseStudies] = useState([]);
  const [rangeFamilies, setRangeFamilies] = useState<any[]>([]);
  const { hasLogin, isAdmin, user } = useSelector(
    (state: RootStore) => state.login,
  );
  const breadcrumbLinks: PreviousLink[] = [
    {
      name: 'Home',
      to: '/home',
    },
    {
      name: 'CASE STUDIES',
    },
  ];
  const [tabs, setTabs] = useState([
    {
      name: 'CASE STUDIES',
      key: 'CASE STUDIES',
    },
  ]);
  useEffect(() => {
    getBrowseCaseStudy({
      subSectorId: Number(searchParams.get('subSectorId')),
      applicationId: Number(searchParams.get('applicationId')),
    }).then((res: any) => {
      setcaseStudies(res);
    });
    getRangeFamilies(false).then(async (res: RangeFamily[]) => {
      setRangeFamilies(res);
    });
  }, []);

  const intl = useIntl();
  const getTranslatedMessage = (id: string) => intl.formatMessage({ id });

  const idsToNames = (id: number | number[], entities: any[]) => {
    if (!id) {
      return null;
    }

    const ids = _.isNumber(id) ? [id as number] : id;
    return _(entities)
      .filter((x) => _.includes(ids, x.id))
      .map('name')
      .filter(_.identity)
      .sort()
      .join(', ');
  };

  const {
    categories,
    subCategories,
    productTypes,
    sectors,
    subSectors,
    applications,
    subApplications,
    benefits,
  } = useSelector((state: RootStore) => state.lookups);

  const getProductSubCategory = (id: number): string => {
    if (!subCategories) {
      return '';
    }

    const productSubCategory = find(subCategories, (item) => item.id === id);
    return productSubCategory?.name || '';
  };

  function fitToColumn(arrayOfArray: any) {
    // get maximum character of each column
    return arrayOfArray[0].map((a: any, i: any) => ({
      wch: Math.max(
        ...arrayOfArray.map((a2: any) => (a2[i] ? a2[i].toString().length : 0)),
      ),
    }));
  }

  const exportToExcel = () => {
    const fileName = getAlphaDigitName(exportFileName) + '.xlsx';
    const tableHeader = [
      getTranslatedMessage('case-details-title'),
      getTranslatedMessage('product-name-label'),
      getTranslatedMessage('fusion-code-label'),
      getTranslatedMessage('product-family'),
      getTranslatedMessage('product-range'),
      getTranslatedMessage('product-category'),
      getTranslatedMessage('product-sub-category'),
      getTranslatedMessage('product-type'),
      getTranslatedMessage('case-details-customer'),
      getTranslatedMessage('previous-product-name'),
      getTranslatedMessage('case-study-sector'),
      getTranslatedMessage('sub-sector-label'),
      getTranslatedMessage('case-study-application'),
      getTranslatedMessage('sub-application'),
      getTranslatedMessage('product-status-Benefits-label'),
      getTranslatedMessage('oem-table-field-notes'),
      getTranslatedMessage('file-link'),
    ];

    const tableData = caseStudies.map((item: any) => {
      return [
        item.title,
        item.product.name,
        item.product.externalId,
        idsToNames(item.product.familyId, rangeFamilies),
        idsToNames(
          item.product.rangeId,
          rangeFamilies.find((data) => data.id == item.product.familyId)
            ?.ranges,
        ),
        idsToNames(
          _.map(item?.product.productCategories, 'categoryId'),
          categories,
        ),
        getProductSubCategory(item.product.subCategoryId),
        idsToNames(
          _.map(item?.product.productProductTypes, 'productTypeId'),
          productTypes,
        ),
        item.customer,
        item.competitor,
        idsToNames(_.map(item?.caseStudySectors, 'sectorId'), sectors),
        idsToNames(_.map(item?.caseStudySubSectors, 'subSectorId'), subSectors),
        idsToNames(
          _.map(item.caseStudyApplications, 'applicationId'),
          applications,
        ),
        idsToNames(
          _.map(item?.caseStudySubApplications, 'applicationId'),
          subApplications,
        ),
        idsToNames(_.map(item?.caseStudyBenefits, 'benefitId'), benefits),
        item.notes,
        { v: window.location.href, t: 'l' },
      ];
    });

    const data = [tableHeader, ...tableData];
    const sheet = XLSX.utils.aoa_to_sheet(data);
    sheet['!cols'] = fitToColumn(data);
    sheet['!autofilter'] = { ref: 'A1:N10' };
    const wb: XLSX.WorkBook = {
      SheetNames: ['Sheet1'],
      Sheets: {
        Sheet1: sheet,
      },
    };
    XLSX.writeFile(wb, fileName, {
      bookType: 'xlsx',
    });
  };

  return (
    <div>
      <NavigationHeader
        // onInputFocus={() => setSearchOpened(true)}
        breadcrumbLinks={breadcrumbLinks}
      />
      <div className={styles.csdivider} />
      <ContainerForDrawer>
        <LeftDrawer className={styles.leftDrawer}>
          <IconField
            className={styles.item}
            icon={<DocumentGreen />}
            label={'Case studies'}
            value={caseStudies.length.toString()}
          />
        </LeftDrawer>

        <RightContainer>
          {user?.role === 'Internal User' && (
            <div className={styles.exportDiv}>
              <div
                className={styles.exportToExcel}
                onClick={() => exportToExcel()}
              >
                <XlsxFileIcon />
                <FormattedMessage id={'oem-table-field-export-to-excel'} />
              </div>
            </div>
          )}

          <div className={styles.container}>
            <Tabs
              className={styles.tabContainer}
              tabContentClassName={styles.tabContent}
              tabItemClassName={styles.tabItemClassName}
              tabItemSelectedClassName={styles.tabItemSelectedClassName}
              selected={tab}
              tabs={tabs}
              onChange={(item) => {
                setTab(item.key);
              }}
            >
              {tab === 'CASE STUDIES' && (
                <>
                  {caseStudies.length > 0 && (
                    <ProductCaseStudies
                      caseStudies={caseStudies}
                      isAdmin={isAdmin}
                      showProduct={true}
                      userRole={user?.role}
                    />
                  )}
                </>
              )}
            </Tabs>
          </div>
        </RightContainer>
      </ContainerForDrawer>
    </div>
  );
};

export default CaseStudies;
